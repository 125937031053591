import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Business.Payouts, State.Root> = {
  Find: (s) => (id: string): ApiResource.Business.Payout | undefined => s.list
    .filter((c) => c.id === id)[0],
  List: (s) => (acct: string): ApiResource.Business.Payout[] => s.list
    .filter((c) => c.account_id === acct),
};

export default getters;
