import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Business.Commission, State.Root> = {
  async Accept({ commit }, { BUSINESS_ID, COMMISSION_ID }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/accept`,
    );

    commit('Push', res.data.data);

    return res.data;
  },
  async ChangeZone({ commit }, { BUSINESS_ID, COMMISSION_ID, form }) {
    const res = await Api().patch(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/zone`,
      form,
    );

    commit('Push', res.data.data);

    return res.data;
  },
  async Find({ commit }, { BUSINESS_ID, COMMISSION_ID }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}`,
    );

    commit('Push', res.data.data);

    return res.data;
  },
  async GetCustomer({ commit }, { BUSINESS_ID, COMMISSION_ID }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/customer`,
    );

    commit('PushCustomer', res.data.data);

    return res.data;
  },
  async GetRating({ commit }, { BUSINESS_ID, COMMISSION_ID }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/rating`,
    );

    commit('PushRating', res.data.data);

    return res.data;
  },
  async List({ commit }, { BUSINESS_ID, query }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/commissions`,
      {
        params: query,
      },
    );

    commit('PushList', res.data);

    return res.data;
  },
  async RateUser({ commit }, { BUSINESS_ID, COMMISSION_ID, form }) {
    const res = await Api().put(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/rating`,
      form,
    );

    commit('PushRating', res.data.data);

    return res.data;
  },
  async Reject({ commit }, { BUSINESS_ID, COMMISSION_ID, form }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/reject`,
      form,
    );

    commit('Push', res.data.data);

    return res.data;
  },
  async SetActive({ commit }, { BUSINESS_ID, COMMISSION_ID }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/active`,
    );

    commit('Push', res.data.data);

    return res.data;
  },
  async SetComplete({ commit }, { BUSINESS_ID, COMMISSION_ID }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/complete`,
    );

    commit('Push', res.data.data);

    return res.data;
  },
  async SetPending({ commit }, { BUSINESS_ID, COMMISSION_ID }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/pending`,
    );

    commit('Push', res.data.data);

    return res.data;
  },
  async Stats({ commit }, { BUSINESS_ID, COMMISSION_ID }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/stats`,
    );

    commit('PushStats', res.data);

    return res.data;
  },
  async Touch({ commit }, { BUSINESS_ID, COMMISSION_ID }) {
    const res = await Api().post(
      `businesses/${BUSINESS_ID}/commissions/${COMMISSION_ID.toLowerCase()}/touch`,
    );

    commit('Push', res.data.data);

    return res.data;
  },
};

export default actions;
