import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Business.Pages, State.Root> = {
  Id: (s) => (id: string): ApiResource.Profile.Page | undefined => s.list.filter(
    (p) => p.id === id,
  )[0],
  Find: (s) => (b: string, id: string): ApiResource.Profile.Page | undefined => s.list
    .filter((p) => p.business_id === b)
    .filter((p) => p.id === id)[0],
  List: (s) => (b: string): ApiResource.Profile.Page[] => s.list
    .filter((p) => p.business_id === b),
};

export default getters;
