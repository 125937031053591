/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource } from '@/util/list';

const mutations: MutationTree<State.Business.Fees> = {
  Empty(state) {
    state.list = [];
  },
  Push(state, { data }: { data: ApiResource.Business.Fee }) {
    pushResource(state.list, data);
  },
  PushList(state, { data }: { data: ApiResource.Business.Fee[] }) {
    data.forEach((e) => pushResource(state.list, e));
  },
};

export default mutations;
