import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Business.Vouchers, State.Root> = {
  Find: (s) => (id: string): ApiResource.Business.InvoiceVoucher | undefined => s.list
    .filter((c) => c.number === id)[0],
  List: (s) => (acct: string): ApiResource.Business.InvoiceVoucher[] => s.list
    .filter((c) => c.invoicee_id === acct),
};

export default getters;
