/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource } from '@/util/list';

const mutations: MutationTree<State.Business.Event> = {
  Push(state, { data }: { data: ApiResource.Event }) {
    pushResource(state.list, data);
  },
  PushList(state, { data }: { data: ApiResource.Event[] }) {
    data.forEach((m) => pushResource(state.list, m));
  },
  PushCommission(state, { data }: { data: ApiResource.Event }) {
    pushResource(state.commissionEvents, data);
  },
  PushCommissionList(state, { data }: { data: ApiResource.Event[] }) {
    data.forEach((m) => pushResource(state.commissionEvents, m));
  },
};

export default mutations;
