import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';
import { blobFromArrayBuffer } from '@/util/blob';

const actions: ActionTree<State.Business.Vouchers, State.Root> = {
  async Find({ commit }, { BUSINESS_ID, VOUCHER_NUMBER }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}/vouchers/${VOUCHER_NUMBER}`);

    commit('Push', res.data);

    return res.data;
  },
  async List({ commit }, { BUSINESS_ID, query }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/vouchers`,
      {
        params: query,
      },
    );

    commit('PushList', res.data);

    return res.data;
  },
  async DownloadPdf(context, { BUSINESS_ID, VOUCHER_NUMBER }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}/vouchers/${VOUCHER_NUMBER}/pdf`, {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    });

    const blob = blobFromArrayBuffer(res.data, 'application/pdf');
    const uri = URL.createObjectURL(blob);

    return uri;
  },
};

export default actions;
