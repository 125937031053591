import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Business.ExternalAccounts, State.Root> = {
  Find: (s) => (id: string): ApiResource.Business.ExternalAccount | undefined => s.list.filter(
    (c) => c.id === id,
  )[0],
  List: (s) => (acct: string): ApiResource.Business.ExternalAccount[] => s.list.filter(
    (c) => c.account_id === acct,
  ),
};

export default getters;
