import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Business.ExternalAccounts, State.Root> = {
  async Find({ commit }, { BUSINESS_ID, EXTERNAL_ACCOUNT_ID }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}/external_accounts/${EXTERNAL_ACCOUNT_ID}`);

    commit('Push', res.data);

    return res.data;
  },
  async List({ commit }, { BUSINESS_ID, query }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/external_accounts`,
      {
        params: query,
      },
    );

    commit('PushList', res.data);

    return res.data;
  },
};

export default actions;
