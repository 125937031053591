/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource, removeResourceById } from '@/util/list';

const mutations: MutationTree<State.Business.ExternalAccounts> = {
  Empty(state) {
    state.list = [];
  },
  RemoveId(state, id: string) {
    removeResourceById(state.list, id);
  },
  Push(state, { data }: { data: ApiResource.Business.ExternalAccount }) {
    pushResource(state.list, data);
  },
  PushList(state, { data }: { data: ApiResource.Business.ExternalAccount[] }) {
    data.forEach((e) => pushResource(state.list, e));
  },
};

export default mutations;
