import { Module } from 'vuex';
import { State } from '@/types';
import getters from './getters';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import mutations from './mutations';

function defaultState(): State.Business.Event {
  return {
    commissionEvents: [],
    list: [],
  };
}

const module: Module<State.Business.Event, State.Root> = {
  namespaced: true,
  state: defaultState(),
  getters,
  actions,
  mutations,
};

export default module;
