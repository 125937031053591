import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Business.Event, State.Root> = {
  List: (s) => (C: string): ApiResource.Event[] => s.list.filter(
    (c) => c.context_commission_id === C,
  ),
  ListCommissionEvents: (s) => s.commissionEvents,
};

export default getters;
