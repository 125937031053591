import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { findResource } from '@/util/list';

const getters: GetterTree<State.Business.Commission, State.Root> = {
  Find: (s) => (B: string, C: string): ApiResource.Commission | undefined => findResource(
    s.list.filter((c) => c.business_id === B), C,
  ),
  FindCustomer: (s) => (U: string): ApiResource.User | undefined => findResource(s.customers, U),
  FindRating: (s) => (U: string): ApiResource.UserRating | undefined => s.ratings
    .filter((r) => r.user_id === U)[0],
  List: (s) => (B: string): ApiResource.Commission[] => s.list.filter((c) => c.business_id === B),
  Stats: (s) => (C: string): ApiResource.CommissionStats | undefined => findResource(s.stats, C),
};

export default getters;
