/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource } from '@/util/list';

const mutations: MutationTree<State.Business.Commission> = {
  Push(state, payload: ApiResource.Commission) {
    pushResource(state.list, payload);
  },
  PushCustomer(state, payload: ApiResource.User) {
    pushResource(state.customers, payload);
  },
  PushRating(state, payload: ApiResource.UserRating) {
    pushResource(state.ratings, payload);
  },
  PushList(state, { data }: { data: ApiResource.Commission[] }) {
    data.forEach((c) => pushResource(state.list, c));
  },
  PushStats(state, { data }: { data: ApiResource.CommissionStats }) {
    pushResource(state.stats, data);
  },
};

export default mutations;
