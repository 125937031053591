import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Business.Pages, State.Root> = {
  async Find({ commit }, { BUSINESS_ID, PAGE_ID }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}/pages/${PAGE_ID}`);

    commit('Push', res.data);

    return res.data;
  },
  async List({ commit }, { BUSINESS_ID, query }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}/pages`, { params: query });

    commit('PushList', res.data);

    return res.data;
  },
  async Create({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().post(`businesses/${BUSINESS_ID}/pages`, form);

    commit('Push', res.data);

    return res.data;
  },
  async Update({ commit }, { BUSINESS_ID, PAGE_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/pages/${PAGE_ID}`, form);

    commit('Push', res.data);

    return res.data;
  },

  async Delete({ commit }, { BUSINESS_ID, PAGE_ID }) {
    const res = await Api().delete(`businesses/${BUSINESS_ID}/pages/${PAGE_ID}`);

    commit('RemoveId', PAGE_ID);

    return void res;
  },
};

export default actions;
