import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';
import { blobFromArrayBuffer } from '@/util/blob';

const actions: ActionTree<State.Business.Payments, State.Root> = {
  async List({ commit }, { BUSINESS_ID, query }) {
    const res = await Api().get(
      `businesses/${BUSINESS_ID}/payments`,
      {
        params: query,
      },
    );

    commit('PushList', res.data);

    return res.data;
  },
  async DownloadFeeInvoice(context, { BUSINESS_ID, FEE_ID }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}/fees/${FEE_ID}/pdf`, {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    });

    const blob = blobFromArrayBuffer(res.data, 'application/pdf');
    const uri = URL.createObjectURL(blob);

    return uri;
  },
};

export default actions;
